import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_DEDUCTIONS } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "title": "Edit Deduction",
      "has-deactivate-button": false
    }
  }, [_c("text-input", {
    key: "cropCode",
    attrs: {
      "disabled": true,
      "label": "Crop Code",
      "span": 12
    }
  }), _c("text-input", {
    key: "id",
    attrs: {
      "disabled": true,
      "label": "Deduct Code",
      "span": 12
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "disabled": true,
      "label": "Deduct Description",
      "span": 12
    }
  }), _c("text-input", {
    key: "shortDescription",
    attrs: {
      "label": "Deduct Short Description",
      "span": 12,
      "rules": "alpha_spaces",
      "max-length": 15
    }
  }), _c("text-input", {
    key: "sampleTypeCode",
    attrs: {
      "label": "Sample Type",
      "disabled": true,
      "span": 12
    }
  }), _c("text-input", {
    key: "sequence",
    attrs: {
      "label": "Sequence",
      "span": 12,
      "rules": "integer"
    }
  }), _c("text-input", {
    key: "jagPrompt",
    style: {
      marginRight: "50%"
    },
    attrs: {
      "label": "Scale Prompt",
      "span": 12,
      "max-length": 50
    }
  }), _c("switch-input", {
    key: "warningTolerance",
    attrs: {
      "label": "Warning Tolerance",
      "span": 12
    }
  }), _c("text-input", {
    key: "warningToleranceLimit",
    attrs: {
      "label": "Warning Tolerance Limit",
      "span": 12,
      "rules": _vm.rule3Whole1Decimal
    }
  }), _c("text-input", {
    key: "warningText",
    attrs: {
      "label": "Warning Text",
      "span": 12,
      "offset": 12,
      "rules": "max:50"
    }
  }), _c("switch-input", {
    key: "observedTolerance",
    attrs: {
      "label": "Observed Tolerance",
      "span": 12
    }
  }), _c("text-input", {
    key: "observedToleranceLimit",
    attrs: {
      "label": "Observed Tolerance Limit",
      "span": 12,
      "rules": _vm.rule3Whole1Decimal
    }
  }), _c("text-input", {
    key: "appliedDeduction",
    attrs: {
      "label": "Applied Deduction",
      "span": 12,
      "offset": 12,
      "max-length": 5,
      "rules": _vm.rule3Whole1Decimal
    }
  })], 1);
};
var staticRenderFns$1 = [];
var EditDeduction_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditDeduction",
  data() {
    return {
      rule3Whole1Decimal: {
        number: true,
        decimal: {
          wholeLength: 3,
          fractionalLength: 1
        }
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "4468feb0", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditDeduction = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "grower.deductions",
      "redirect-route": "/watties-grower/deductions",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("edit-deduction")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditDeduction
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_DEDUCTIONS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
